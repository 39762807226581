import { Link } from '@remix-run/react'
import { ArrowRightIcon } from '@heroicons/react/24/solid'

import { getFirstSeriesOrCompilationSet } from '~/modules/videos/services/series-or-compilation'
import { type SearchItemRendererProps } from './types'

const SearchItemLink = ({ item, onClick, listKey, accentColor }: SearchItemRendererProps) => {
  const setSlug = getFirstSeriesOrCompilationSet(item.sets)?.[0]?.slug

  let linkUrl = `/${listKey}/${item.slug || item.id}`
  const videoUrl = `${setSlug ? `/set/${setSlug}` : ''}/video/${item.slug}`
  const setUrl = `/set/${item.slug}`

  if (listKey === 'video') {
    linkUrl = videoUrl
  } else if (listKey === 'set') {
    linkUrl = setUrl
  }

  return (
    <Link
      to={linkUrl}
      prefetch="intent"
      onClick={onClick}
      className="group flex items-center justify-between rounded-md px-3 py-2 hover:bg-tigerGrey">
      <div className="flex items-center space-x-2">
        {item.thumbnail && (
          <img
            src={item.thumbnail}
            alt={item.title}
            className="block aspect-video w-16 rounded-sm bg-tigerGrey"
            loading="lazy"
          />
        )}
        <p>{item.title}</p>
      </div>
      <ArrowRightIcon className={`ml-1 h-4 w-4 ${accentColor}`} />
    </Link>
  )
}

export default SearchItemLink
