import { useLoaderData, useSubmit } from '@remix-run/react'
import * as React from 'react'

import Footer from '~/modules/layout/components/Footer'
import Header from '~/modules/layout/components/Header'
import BannerAlert from '~/components/BannerAlert'
import AuthMessage from '~/modules/auth/components/AuthMessage'
import type { SessionUser } from '~/modules/auth/types/session'
import { ToastProvider } from '~/components/Toast'
import { socials } from '~/config/links'
import { type loader } from '~/root'

const Layout = ({ children }: React.PropsWithChildren<{}>) => {
  const submit = useSubmit()
  const data = useLoaderData<typeof loader>()

  return (
    <>
      {data?.isMaintenanceMode ? (
        <>{children}</>
      ) : (
        <ToastProvider>
          <div className="grid min-h-full grid-rows-layout">
            <div className="z-20">
              <BannerAlert />
              <Header
                onClickLogout={() => {
                  submit({ name: 'logout' }, { method: 'post' })
                }}
                isLoggedIn={!!data?.user?.id}
              />
            </div>
            <main className="main-content z-10 bg-white text-dark dark:bg-dark dark:text-white">
              <AuthMessage user={data?.user as SessionUser | null} />
              {children}
            </main>
            <Footer socials={socials} />
          </div>
        </ToastProvider>
      )}
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(data?.ENV)}`
        }}
      />
    </>
  )
}

export default Layout
