import useAuthMessage from './useAuthMessage'
import type { SessionUser } from '~/modules/auth/types/session'

export interface AuthMessageProps {
  user?: SessionUser | null
}
export const AuthMessage = ({ user }: AuthMessageProps) => {
  useAuthMessage({ user })
  return null
}

export default AuthMessage
