import type { Maybe, Set } from '~/graphql/codegen/graphql'

export const getFirstSeriesOrCompilationSet = (sets: Maybe<Set[]> | undefined) => {
  if (!sets || !Array.isArray(sets)) return undefined

  // Sort sets by title
  sets = sets.sort((a, b) => (a.title && b.title ? a.title.localeCompare(b.title) : 0))

  // In practice, a video will generally only be in one series, but may be part of multiple compilations
  const series = sets.filter(set => set.type === 'series')
  const compilation = sets.filter(set => set.type === 'compilation')

  // If it's part of a series, return the first series
  if (series.length > 0) {
    return series[0] ? [series[0]] : []

    // Otherwise, if it's part of a compilation, return the first compilation
  } else if (compilation.length > 0) {
    return compilation[0] ? [compilation[0]] : []
  }
}
