import { gql } from 'graphql-request'
import type { DocumentRendererProps } from '@keystone-6/document-renderer'
import { useLoaderData } from '@remix-run/react'

import type { Intent } from '~/services/theme-service'
import { backend } from '~/utils/api'
import Banner from './Banner'
import type { loader } from '~/root'
import GenericDocument from '~/components/GenericDocument'

export const GET_BANNER_ALERT = gql`
  query GetBannerAlert($where: BannerAlertWhereInput!, $orderBy: [BannerAlertOrderByInput!]!) {
    bannerAlerts(where: $where, orderBy: $orderBy) {
      id
      kind
      title
      userDismissable
      bodyText {
        document
      }
    }
  }
`

export type BannerAlertType = {
  id: string
  kind: Intent
  title: string
  userDismissable: boolean
  bodyText: {
    document: DocumentRendererProps['document']
  }
}

export type BannerAlerts = Array<BannerAlertType>

interface BannerAlertsResponse {
  bannerAlerts: BannerAlerts
}

export const getBannerAlert = async ({ request, isAuthenticated }: { request: Request; isAuthenticated: boolean }) => {
  const now = new Date().toISOString()

  const where = {
    where: {
      status: { equals: 'published' },
      publishDate: { lte: now },

      AND: [
        {
          OR: [
            {
              expiresAt: {
                gt: now
              }
            },
            {
              expiresAt: null
            }
          ]
        },
        {
          OR: [
            {
              authenticatedUsersOnly: { equals: false }
            },
            {
              authenticatedUsersOnly: { equals: isAuthenticated }
            }
          ]
        },
        {
          OR: [
            {
              guestsOnly: { equals: false }
            },
            {
              guestsOnly: { equals: !isAuthenticated }
            }
          ]
        }
      ]
    },
    orderBy: [
      {
        createdAt: 'desc'
      }
    ]
  }

  const response = await backend<BannerAlertsResponse>(GET_BANNER_ALERT, where, request)
  const data = response?.data
  const bannerAlert = data?.bannerAlerts?.[0]
  return bannerAlert
}

const BannerAlert = () => {
  const data = useLoaderData<typeof loader>()

  const bannerAlert = data?.bannerAlert

  if (!bannerAlert) {
    return null
  }

  return (
    <Banner
      intent={bannerAlert.kind}
      isRounded={false}
      id={bannerAlert.id}
      dismissable={bannerAlert.userDismissable}
      className="z-20">
      <div className="flex-1 text-center text-xs md:text-sm lg:text-base">
        {bannerAlert.title && <h3 className="pb-1 font-display">{bannerAlert.title}</h3>}
        <GenericDocument document={bannerAlert.bodyText.document} />
      </div>
    </Banner>
  )
}

export default BannerAlert
