import * as Sentry from '@sentry/remix'
import { cssBundleHref } from '@remix-run/css-bundle'
import type { ActionFunctionArgs, LinksFunction, LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json, redirect } from '@remix-run/node'
import { Outlet } from '@remix-run/react'

import { type BannerAlertType, getBannerAlert } from './components/BannerAlert'
import Layout from '~/modules/layout/components/Layout'
import Document from '~/modules/layout/components/Document'
import ErrorBoundary from '~/modules/error-handling/components/ErrorBoundary'
import baseStyles from './styles/index.css?url'
import tailwindUrl from './styles/tailwind.css?url'
import type { SessionUser } from './modules/auth/types/session'
import { checkAuth, destroyAuth } from './modules/auth/services/auth-sessions.server'

export { ErrorBoundary }

export const links: LinksFunction = () => [
  ...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
  { rel: 'stylesheet', href: tailwindUrl },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossOrigin: 'anonymous' },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&family=Quicksand:wght@500;700&display=swap',
    as: 'font'
  },
  {
    rel: 'stylesheet',
    href: 'https://use.typekit.net/vqv2bkl.css',
    as: 'font'
  },
  { rel: 'stylesheet', href: baseStyles }
]

export const meta: MetaFunction<typeof loader> = ({ location, data }) => {
  const description = 'Creating positive memories for children.'
  const url = `${data?.frontendUrl}${location.pathname}`
  const image = `${data?.frontendUrl}/pands-logo.png`
  return [
    { title: 'Pevan & Sarah' },
    { name: 'description', content: description },
    { keywords: 'music videos,videos,education,education resources' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: url },
    { property: 'og:image', content: image },
    { property: 'og:description', content: description },
    { property: 'twitter:image', content: image },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:url', content: url },
    { property: 'twitter:creator', content: '@pevananadsarah' },
    { property: 'twitter:site', content: '@pevanandsarah' },
    { property: 'twitter:title', content: 'Pevan & Sarah' },
    { property: 'twitter:description', content: description }
  ]
}

export const action = async ({ request }: ActionFunctionArgs) => {
  const form = await request.formData()

  if (form.get('name') === 'logout') {
    return destroyAuth(request)
  }

  return null
}

export type EnvironmentVariables = {
  SENTRY_DSN?: string
  SENTRY_ENVIRONMENT?: string
  MAINTENANCE_MODE?: string
  GRAPHQL_URL?: string
}

export type RootLoaderData = {
  bannerAlert?: BannerAlertType
  frontendUrl: string
  // backendUrl: string;
  user: SessionUser | null
  ENV: EnvironmentVariables
  isMaintenanceMode: boolean
  recaptchaSiteKey: string
} | null

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  const isMaintenanceMode = Boolean(parseInt(process.env.MAINTENANCE_MODE || '0', 10))

  if (isMaintenanceMode && url.pathname !== '/maintenance') {
    throw redirect('/maintenance')
  }

  const authResponse = await checkAuth(request)
  const { user } = authResponse

  // Set sentry user on the server!
  if (user) {
    Sentry.setUser({ email: user.email, id: user.id, name: user.name })
  } else {
    Sentry.setUser(null)
  }

  const bannerAlert = await getBannerAlert({ request, isAuthenticated: !!user })

  const data: RootLoaderData = {
    bannerAlert,
    frontendUrl: process.env.FRONTEND_URL!,
    // backendUrl: process.env.BACKEND_URL!,
    ENV: {
      SENTRY_DSN: process.env.SENTRY_DSN,
      SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
      MAINTENANCE_MODE: process.env.MAINTENANCE_MODE,
      GRAPHQL_URL: process.env.GRAPHQL_URL
    },
    recaptchaSiteKey: process.env.RECAPTCHA_SITE_KEY!,
    isMaintenanceMode,
    user: user
      ? {
          id: user.id,
          email: user.email,
          name: user.name,
          subscriptionStatus: user.subscriptionStatus,
          validTo: user.validTo
        }
      : null
  }

  return json(data)
}

const App = () => (
  <Document>
    <Layout>
      {/* This is the outlet for the nested route content */}
      <Outlet />
    </Layout>
  </Document>
)

export default App
