import { ChevronUpIcon } from '@heroicons/react/24/outline'
import { useCallback, useEffect, useState } from 'react'

const ScrollToTopButton = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY > 500) {
        setShow(true)
      } else {
        setShow(false)
      }
    }

    scrollListener()

    window.addEventListener('scroll', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  return show ? (
    <button
      onClick={scrollToTop}
      className="fixed bottom-4 right-4 z-30 rounded-lg bg-mid p-2 shadow-md hover:bg-grey"
      title="Scroll to top">
      <ChevronUpIcon className="h-6 w-6 text-white" />
    </button>
  ) : null
}
export default ScrollToTopButton
