import { Link } from '@remix-run/react'
import React, { useEffect } from 'react'
import { SocialIcon } from 'react-social-icons'
import type { WebLink } from '~/config/links'
import { footerLinks } from '~/config/links'
import { renderSignupForm } from '~/lib/flodesk'

interface FooterProps {
  socials: WebLink[]
}

const Footer: React.FC<FooterProps> = ({ socials }) => {
  // NOTE: This is a workaround to ensure the render of Flodesk form in production
  useEffect(() => {
    renderSignupForm()

    const timer = setTimeout(() => {
      renderSignupForm()
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <footer className="main-footer z-20 flex flex-col items-center justify-center bg-black p-4 pt-2 text-white shadow-inner">
      <div className="mb-6 grid w-full" id="fd-form-660397b9701cf0bc64ab4110"></div>
      <div className="grid w-full max-w-5xl grid-cols-2 sm:grid-cols-4">
        <div className="order-1 space-y-2 divide-y divide-grey">
          <p className="text-sm text-light">Connect with us</p>
          <ul className="pt-2">
            {socials.map(([name, href]) => (
              <li key={name}>
                <div className="flex items-center py-1">
                  <SocialIcon
                    network={href.includes('apple') ? 'itunes' : name.toLowerCase()}
                    fgColor="white"
                    style={{ height: 28, width: 28 }}
                    url={href}
                    target="_blank"
                    rel="noreferrer nofollow"
                    id={name}
                  />
                  <label
                    htmlFor={name}
                    className="cursor-pointer pl-2 hover:text-lightOrange"
                    onClick={() => {
                      window.open(href, '_blank', 'noopener noreferrer')
                    }}>
                    {name}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="order-3 col-span-2 mt-8 flex flex-col justify-between text-center sm:order-2 sm:mt-0">
          <div className="space-y-2 divide-y divide-grey">
            <p className="text-sm text-light">Acknowledgement of Country</p>
            <p className="mx-auto max-w-prose pl-4 pt-2 text-left">
              Pevan & Sarah work and live on Wurundjeri land. We acknowledge the traditional custodians of this land and
              pay our respects to the Elders both past & present, for they hold the memories, traditions, culture and
              music of their people.
            </p>
          </div>
          <div>
            <p className="mt-8 font-display font-medium">Australia&apos;s favourite kids duo</p>
            <p>
              &copy; <span className="logo-gradient bg-clip-text text-transparent">Pevan & Sarah</span>{' '}
              {new Date().getFullYear()}
            </p>
          </div>
        </div>
        <div className="order-2 space-y-2 divide-y divide-grey text-right sm:order-3">
          <p className="text-sm text-light">Sitemap</p>
          <ul className="pt-2">
            {footerLinks.map(([name, path]) => (
              <li key={name} className="flex h-9 items-center justify-end">
                <Link to={path} prefetch="intent" className="inline-block hover:text-lightOrange">
                  <span>{name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
